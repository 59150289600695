import { axiosVendor } from "./ApiServices";
import { AxiosResponse } from "axios";
import * as CategoriesResponse from "common/types/CategoriesResponse";

export const GetProgramByFilter = async (program: any): Promise<any> => {
  return axiosVendor.get(
    `/programs?pageSize=${program.pageSize}&pageOrder=${program.pageOrder}&search=${program.search}&pageNumber=${program.pageNumber}`
  );
};

export const AddProgramApi = async (programData: any): Promise<any> => {
  return axiosVendor.post(`/programs`, programData);
};

export const GetProgramType = async (contentSource: any): Promise<any> => {
  return axiosVendor.get(`/programsType?pageSize=500&pageOrder=id desc`);
};

export const GetStatesApi = async (program: any): Promise<any> => {
  return axiosVendor.get(`/states?pageSize=500&pageOrder=code asc`);
};

export const GetCountriesApi = async (program: any): Promise<any> => {
  return axiosVendor.get(`/countries?pageSize=500&pageOrder=code asc`);
};

export const GetAccreditationApi = async (program: any): Promise<any> => {
  return axiosVendor.get(
    `/accreditations?pageSize=500&pageOrder=id desc&isActive=1`
  );
};

export const GetCertificationApi = async (
  accreditationCode: string
): Promise<any> => {
  return axiosVendor.get(
    `/certificateTemplates?pageSize=500&pageOrder=id desc&active=true&accreditationCode=${accreditationCode}`
  );
};

export const PatchProgramById = async (id: number, body: any): Promise<any> => {
  return axiosVendor.patch(`/programs/${id}`, body);
};

export const GetClassificationApi = async (
  classification: any
): Promise<AxiosResponse<CategoriesResponse.Root>> => {
  const queryParams = {
    pageSize: 500,
    pageOrder: "id desc",
  };
  return axiosVendor.get(`/categories`, { params: queryParams });
};
export const GetCertificationsListApi = async (
  search: any
): Promise<AxiosResponse<any>> => {
  const queryParams = {
    pageSize: 500,
    pageOrder: "id desc",
    search:search
  };
  return axiosVendor.get(`/certifications`, { params: queryParams });
};

export const GetSpecialtiesApi = async (
): Promise<AxiosResponse<CategoriesResponse.Root>> => {
  const queryParams = {
    pageSize: 500,
    pageOrder: "id desc",
  };
  return axiosVendor.get(`/specialties`, { params: queryParams });
};

export const GetQualificationApi = async (
): Promise<AxiosResponse<CategoriesResponse.Root>> => {
  const queryParams = {
    pageSize: 500,
    pageOrder: "id desc",
  };
  return axiosVendor.get(`/professions`, { params: queryParams });
};


export const GetProgramByIdAPi = async (id: string): Promise<AxiosResponse<any>> => {
  return axiosVendor.get(`/programs/${id}`);
};

export const PutProgramByIdAPi = async (
  id: string,
  formData: any
): Promise<any> => {
  return axiosVendor.put(`/programs/${id}`, formData);
};
